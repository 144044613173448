module.exports = {
  NAME: 'Plenty Defi',
  API: {
    url: 'https://api.coingecko.com/api/v3/coins/tezos?localization=false&tickers=false&community_data=false&developer_data=false&sparkline=false',
    API_KEY: '4824FE50-DB6E-4316-B099-72283C964891',
    tezToolTokenPrice: 'https://api.teztools.io/token/prices',
  },
  RPC_NODES: {
    testnet: 'https://testnet.tezster.tech',
    mainnet: 'https://tezos-prod.cryptonomic-infra.tech/',
  },
  TZKT_NODES: {
    mainnet: 'https://api.tzkt.io',
    testnet: 'https://api.granadanet.tzkt.io',
  },
  TOKENS_PAGE: {
    mainnet: {
      API_URL: 'https://indexer.plentydefi.com/',
    },
  },
  STAKING_CONTRACTS: {
    POOLS: {
      mainnet: {
        PLENTY: {
          active: [],
          inactive: [
            {
              address: 'KT1QqjR4Fj9YegB37PQEqXUPHmFbhz6VJtwE',
              mapId: 4494,
              decimal: 18,
              tokenDecimal: 18,
            },
            {
              address: 'KT1UDe1YP963CQSb5xN7cQ1X8NJ2pUyjGw5T',
              mapId: 3949,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        USDtz: {
          active: [],
          inactive: [
            {
              address: 'KT1K5cgrw1A8WTiizZ5b6TxNdFnBq9AtyQ7X',
              mapId: 3953,
              decimal: 18,
              tokenDecimal: 6,
            },
            {
              address: 'KT1MBqc3GHpApBXaBZyvY63LF6eoFyTWtySn',
              mapId: 4490,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        ETHtz: {
          active: [],
          inactive: [
            {
              address: 'KT1J7v85udA8GnaBupacgY9mMvrb8zQdYb3E',
              mapId: 3950,
              decimal: 18,
              tokenDecimal: 18,
            },
            {
              address: 'KT19asUVzBNidHgTHp8MP31YSphooMb3piWR',
              mapId: 4491,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        hDAO: {
          active: [],
          inactive: [
            {
              address: 'KT1Vs8gqh7YskPnUQMfmjogZh3A5ZLpqQGcg',
              mapId: 3952,
              decimal: 18,
              tokenDecimal: 6,
            },
            {
              address: 'KT1Ga15wxGR5oWK1vBG2GXbjYM6WqPgpfRSP',
              mapId: 4496,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        wLINK: {
          active: [],
          inactive: [
            {
              address: 'KT1JCkdS3x5hTWdrTQdzK6vEkeAdQzsm2wzf',
              mapId: 3948,
              decimal: 18,
              tokenDecimal: 18,
            },
            {
              address: 'KT1KyxPitU1xNbTriondmAFtPEcFhjSLV1hz',
              mapId: 4492,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        wMATIC: {
          active: [],
          inactive: [
            {
              address: 'KT1TNzH1KiVsWh9kpFrWACrDNnfK4ihvGAZs',
              mapId: 3947,
              decimal: 18,
              tokenDecimal: 18,
            },
            {
              address: 'KT1XherecVvrE6X4PV5RTwdEKNzA294ZE9T9',
              mapId: 4493,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
        WRAP: {
          active: [],
          inactive: [
            {
              address: 'KT18oB3x8SLxMJq2o9hKNupbZZ5ZMsgr2aho',
              mapId: 7988,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
        },
      },
    },
    FARMS: {
      mainnet: {
        'PLENTY - XTZ': {
          active: [
            {
              address: 'KT1JQAZqShNMakSNXc2cgTzdAWZFemGcU6n1',
              mapId: 4503,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
          inactive: [
            {
              address: 'KT1BfQLAsQNX8BjSBzgjTLx3GTd3qhwLoWNz',
              mapId: 3962,
              decimal: 18,
              tokenDecimal: 6,
            },
          ],
        },
        'KALAM - XTZ': {
          active: [],
          inactive: [
            {
              address: 'KT1DjDZio7k2GJwCJCXwK82ing3n51AE55DW',
              mapId: 4488,
              decimal: 18,
              tokenDecimal: 10,
            },
          ],
        },
        'PLENTY - wBUSD': {
          active: [
            {
              address: 'KT1KJhxkCpZNwAFQURDoJ79hGqQgSC9UaWpG',
              mapId: 10768,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
          inactive: [],
        },
        'PLENTY - wUSDC': {
          active: [
            {
              address: 'KT1Kp3KVT4nHFmSuL8bvETkgQzseUYP3LDBy',
              mapId: 11019,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'PLENTY - wWBTC': {
          active: [
            {
              address: 'KT1M82a7arHVwcwaswnNUUuCnQ45xjjGKNd1',
              mapId: 11057,
              decimal: 18,
              tokenDecimal: 13,
            },
          ],
          inactive: [],
        },
        'PLENTY - wMATIC': {
          active: [
            {
              address: 'KT1UP9XHQigWMqNXYp9YXaCS1hV9jJkCF4h4',
              mapId: 11823,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
          inactive: [],
        },
        'PLENTY - wLINK': {
          active: [
            {
              address: 'KT1UqnQ6b1EwQgYiKss4mDL7aktAHnkdctTQ',
              mapId: 11819,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
          inactive: [],
        },
        'PLENTY - USDtz': {
          active: [
            {
              address: 'KT1VCrmywPNf8ZHH95HKHvYA4bBQJPa8g2sr',
              mapId: 11821,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'PLENTY - hDAO': {
          active: [
            {
              address: 'KT1W3DtcPXbD7MMmtUdk3F352G6CYFSpwUUS',
              mapId: 13080,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'PLENTY - ETHtz': {
          active: [
            {
              address: 'KT1EVfYFoSpte3PnE4tPoWuj1DhNPVQwrW5Y',
              mapId: 13081,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
          inactive: [],
        },
        'PLENTY - wWETH': {
          active: [
            {
              address: 'KT1CBh8BKFV6xAH42hEdyhkijbwzYSKW2ZZC',
              mapId: 13082,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
          inactive: [],
        },
        'PLENTY - kUSD': {
          active: [
            {
              address: 'KT1MmAy4mSbZZVzPoYbK3M4z3GWUo54UTiQR',
              mapId: 13083,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
          inactive: [],
        },
        'PLENTY - QUIPU': {
          active: [
            {
              address: 'KT1FsMiweyRTog9GGNC22hiMTFVRPrGs3eto',
              mapId: 13085,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'PLENTY - WRAP': {
          active: [
            {
              address: 'KT1K9kLuhq9AJjDAgbJdKGBiP9927WsRnjP6',
              mapId: 14292,
              decimal: 18,
              tokenDecimal: 13,
            },
          ],
          inactive: [],
        },
        'PLENTY - UNO': {
          active: [
            {
              address: 'KT1CWNVmHs6RRbLzwA3P19h7Wa9smnDrAgpS',
              mapId: 14293,
              decimal: 18,
              tokenDecimal: 13,
            },
          ],
          inactive: [],
        },
        'PLENTY - SMAK': {
          active: [
            {
              address: 'KT1VwZPZ4bcPQYS1C4yRvmU4giQDXhEV81WD',
              mapId: 14299,
              decimal: 18,
              tokenDecimal: 10,
            },
          ],
          inactive: [],
        },
        'PLENTY - KALAM': {
          active: [
            {
              address: 'KT1UTvMuyRggQe9q1hrh7YLh7vxffX2egtS6',
              mapId: 14295,
              decimal: 18,
              tokenDecimal: 14,
            },
          ],
          inactive: [],
        },
        'PLENTY - tzBTC': {
          active: [
            {
              address: 'KT1RwFV1xQU2E9TsXe1qzkdwAgFWaKk8bfAa',
              mapId: 14291,
              decimal: 18,
              tokenDecimal: 13,
            },
          ],
          inactive: [],
        },
        'PLENTY - uUSD': {
          active: [
            {
              address: 'KT1HSYQ9NLTQufuvNUwMhLY7B9TX8LDUfgsr',
              mapId: 15911,
              decimal: 18,
              tokenDecimal: 14,
            },
          ],
          inactive: [],
        },
        'PLENTY - GIF': {
          active: [],
          inactive: [
            {
              address: 'KT1UH21n4iwXu7gGrh34RKZfRewpcgQLdbXq',
              mapId: 17071,
              decimal: 18,
              tokenDecimal: 13,
              dualInfo: {
                tokenFirst: {
                  symbol: 'PLENTY',
                  tokenContract: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
                  tokenDecimal: 18,
                  tokenType: 'FA1.2',
                  tokenId: 0,
                  rewardContract: 'KT1KPSKAsmM9k28Lsbk1mmrujHpCnQHxKhKS',
                  rewardMapId: 17068,
                },
                tokenSecond: {
                  symbol: 'GIF',
                  tokenContract: 'KT1XTxpQvo7oRCqp85LikEZgAZ22uDxhbWJv',
                  tokenDecimal: 9,
                  tokenType: 'FA2',
                  tokenId: 0,
                  rewardContract: 'KT1LQS3kyhaaW6thmW96anY71gZ92ECGU7Ja',
                  rewardMapId: 17069,
                },
              },
            },
          ],
        },
        'PLENTY - YOU': {
          active: [
            {
              address: 'KT1MkXtVBuCKtxqSh7APrg2d7ThGBmEf4hnw',
              mapId: 20167,
              decimal: 18,
              tokenDecimal: 15,
            },
          ],
          inactive: [],
        },
        'PLENTY - wDAI': {
          active: [
            {
              address: 'KT1FJzDx9AwbuNHjhzQuUxxKUMA9BQ7DVfGn',
              mapId: 20166,
              decimal: 18,
              tokenDecimal: 18,
            },
          ],
          inactive: [],
        },
        'PLENTY - wUSDT': {
          active: [
            {
              address: 'KT1S4XjwGtk55TmsMqSdazEMrH4pGA3NMXhz',
              mapId: 20160,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'PLENTY - cTez': {
          active: [
            {
              address: 'KT1MfMMsYX34Q9cEaPtk4qkQ6pojA7D2nsgr',
              mapId: 21513,
              decimal: 18,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'uUSD - YOU': {
          active: [
            {
              address: 'KT1KGKzNGX1NDP3hGcipzyqVMCkwWbH76NJU',
              mapId: 22800,
              decimal: 12,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        'uUSD - wUSDC': {
          active: [
            {
              address: 'KT1DfYVe4CaE9S6Sm3SEfhFYVZ9XzJbkQDqs',
              mapId: 22814,
              decimal: 12,
              tokenDecimal: 9,
            },
          ],
          inactive: [],
        },
        'uUSD - uDEFI': {
          active: [
            {
              address: 'KT1RENb4rWNFPP5QJSYT4rRGGsk1tPgLLwu2',
              mapId: 22813,
              decimal: 12,
              tokenDecimal: 12,
            },
          ],
          inactive: [],
        },
        // KT1DfYVe4CaE9S6Sm3SEfhFYVZ9XzJbkQDqs
      },
    },
    PONDS: {
      mainnet: {
        WRAP: {
          active: [],
          inactive: [
            {
              address: 'KT1GotpjdBaxt2GiMFcQExLEk9GTfYo4UoTa',
              mapId: 7985,
              decimal: 8,
              tokenDecimal: 18,
            },
          ],
        },
        KALAM: {
          active: [],
          inactive: [
            {
              address: 'KT1WfLprabHVTnNhWFigmopAduUpxG5HKvNf',
              mapId: 5137,
              decimal: 10,
              tokenDecimal: 18,
            },
          ],
        },
      },
    },
  },
  TOKEN_CONTRACTS: {
    testnet: {
      PLENTY: {
        address: 'KT1PHbg4Dqmg9zWwuWQjo4dDTsgJ5svdyXdH',
        mapId: 22612,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
    },
    mainnet: {
      PLENTY: {
        address: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
        mapId: 3943,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      xPLENTY: {
        address: 'KT1Rpviewjg82JgjGfAKFneSupjAR1kUhbza',
        mapId: 18153,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      wMATIC: {
        address: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        decimal: 18,
        type: 'FA2',
        tokenId: 11,
      },
      wLINK: {
        address: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        mapId: 1772,
        decimal: 18,
        type: 'FA2',
        tokenId: 10,
      },
      USDtz: {
        address: 'KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9',
        mapId: 36,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      ETHtz: {
        address: 'KT19at7rQUvyjxnZ2fBv7D9zc8rkyG7gAoU8',
        mapId: 199,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      hDAO: {
        address: 'KT1AFA2mwNUMNd4SsujE1YYp29vd8BZejyKW',
        mapId: 515,
        decimal: 6,
        type: 'FA2',
        tokenId: 0,
      },
      WRAP: {
        address: 'KT1LRboPna9yQY9BrjtQYDS1DVxhKESK4VVd',
        mapId: 1777,
        decimal: 8,
        type: 'FA1.2',
        tokenId: 0,
      },
      KALAM: {
        address: 'KT1A5P4ejnLix13jtadsfV9GCnXLMNnab8UT',
        mapId: 4178,
        decimal: 10,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - XTZ': {
        address: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
        mapId: 3956,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'KALAM - XTZ': {
        address: 'KT1J3wTYb4xk5BsSBkg6ML55bX1xq7desS34',
        mapId: 4353,
        decimal: 6,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wBUSD': {
        address: 'KT1UC3vcVZ4K9b39uQxaMNA2N1RuJXKLCnoA',
        mapId: 10749,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wUSDC': {
        address: 'KT1Gz1mx1jm7JHqU7GuMVWF6soB9RjsfLN3o',
        mapId: 10755,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wWBTC': {
        address: 'KT1La1qZiJtDRcd9ek8w5KYD47i9MQqAQHmP',
        mapId: 11051,
        decimal: 13,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wMATIC': {
        address: 'KT1WCGPFvy97wwGxewKfvTr1QYPvpEgUKToS',
        mapId: 11807,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wLINK': {
        address: 'KT1Brqb3JvXNKzwjW82F8pUAxQ7ipCfApWki',
        mapId: 11811,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - USDtz': {
        address: 'KT18qSo4Ch2Mfq4jP3eME7SWHB8B8EDTtVBu',
        mapId: 11814,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - hDAO': {
        address: 'KT1B2SzTBtb7PgTePbDLV5BmUZQ2PC1sdSHZ',
        mapId: 12952,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - ETHtz': {
        address: 'KT1VvcbAjMWHVUbhLENpiVBejbzXYDt3PusE',
        mapId: 12955,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wWETH': {
        address: 'KT1SULRhejhoBWUmMnU53YHJrQZ3rxqsamdm',
        mapId: 12959,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - kUSD': {
        address: 'KT1XTpd8JPexGxBL2dAmU9h2o9fcd9LEUG2t',
        mapId: 12963,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - QUIPU': {
        address: 'KT1VDSyTqS95wkB4tRGy6saMa4izRKwrEg26',
        mapId: 12966,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - WRAP': {
        address: 'KT1AHndbp9xVpaJrfTHfYzSXKHDVHMdKxcW2',
        mapId: 14096,
        decimal: 13,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - UNO': {
        address: 'KT1B6ALzWXQp5wUavuo2VTMNjRdTGm8wx37w',
        mapId: 14100,
        decimal: 13,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - SMAK': {
        address: 'KT1NLZah1MKeWuveQvdsCqAUCjksKw8J296z',
        mapId: 14107,
        decimal: 10,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - KALAM': {
        address: 'KT1G3QTnrpWNhZr9x3Prprw3GH6gAMqV113D',
        mapId: 14104,
        decimal: 14,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - tzBTC': {
        address: 'KT1SqQimKz3RbQbckpSHhn4nanUmDuRqkFH3',
        mapId: 14093,
        decimal: 13,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - uUSD': {
        address: 'KT1E8CrG6uznYAG9vZVGtApMJwwTScxPEUKq',
        mapId: 15796,
        decimal: 14,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - GIF': {
        address: 'KT1MVLRQ7K7275oHDvbtKcUzNJsPBstZGKUp',
        mapId: 17061,
        decimal: 13,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - YOU': {
        address: 'KT1UaU5fbSYqYeFmhmsjLkqQXZ1ZG54Qs2vh',
        mapId: 20020,
        decimal: 15,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wDAI': {
        address: 'KT19vdNapeT6MALXvkvW745KiVKGXmD4AZq5',
        mapId: 20016,
        decimal: 18,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - wUSDT': {
        address: 'KT1PcM1LUqgVdkXqKvZ4CeC9aiwLgYYCEHMH',
        mapId: 20024,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'PLENTY - cTez': {
        address: 'KT1LdX5pUkZZGwYPePcAFSpETbqfbGCwJpfw',
        mapId: 21490,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'uUSD - wUSDC': {
        address: 'KT1A2W6mBjn49yqzjJbt9T7xNVD9gDeL45qD',
        mapId: 22767,
        decimal: 9,
        type: 'FA1.2',
        tokenId: 0,
      },
      'uUSD - YOU': {
        address: 'KT1Tmncfgpp4ZSp6aEogL7uhBqHTiKsSPegK',
        mapId: 22770,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      'uUSD - uDEFI': {
        address: 'KT1RQvdYD9yc763j8FiVLyXbKPVVbZqGRx5m',
        mapId: 22802,
        decimal: 12,
        type: 'FA1.2',
        tokenId: 0,
      },
      // PlentywUSDTlp: {
      //   ICON: '',
      //   TOKEN_CONTRACT: 'KT1PcM1LUqgVdkXqKvZ4CeC9aiwLgYYCEHMH',
      //   READ_TYPE: 'FA1.2',
      //   TOKEN_ID: 0,
      //   TOKEN_DECIMAL: 12,
      //   CALL_TYPE: 'FA1.2',
      // },
    },
  },
  SERVERLESS_BASE_URL: {
    mainnet: 'https://w0sujgfj39.execute-api.us-east-2.amazonaws.com/v1',
    testnet: 'https://testnet.dummy-api.us-east-10.amazonaws.com/v1',
  },
  SERVERLESS_REQUEST: {
    mainnet: {
      'HOME-PAGE-TVL': '/tvl',
      'PLENTY-STATS': '/homestats',
    },
    testnet: {},
  },
  AMM: {
    testnet: {
      PLENTY: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1TbkspJ8AzLmNs3ko9xr46ZE4zLpyZu9tX',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          wDAI: {
            contract: 'KT1Qc52zbRaKi5cwCBnx8o4FSDbdxuePLqYB',
            property: 'token2_pool',
            liquidityToken: 'PltDaiLp',
          },
          WRAP: {
            contract: 'KT1HePFs4aA5u6A5BDw3ot7LuXvqa4ebqrTQ',
            property: 'token2_pool',
            liquidityToken: 'WrapLP',
          },
        },
      },
      WRAP: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1L1xYJit22TmuhDXaeng4AZDhRqZwcacNj',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        CALL_TYPE: 'FA2',
        DEX_PAIRS: {
          wDAI: {
            contract: 'KT1PnFKMA2v1ngvYUh5UKtaDuM5WxskrunFm',
            property: 'token1_pool',
            liquidityToken: 'WrapDaiLp',
          },
          PLENTY: {
            contract: 'KT1HePFs4aA5u6A5BDw3ot7LuXvqa4ebqrTQ',
            property: 'token1_pool',
            liquidityToken: 'WrapLP',
          },
        },
      },
      wDAI: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1DJwRryZ11dGDnqmmRtTiSsgxQDY4bw3j4',
        READ_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA2',
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1Qc52zbRaKi5cwCBnx8o4FSDbdxuePLqYB',
            property: 'token1_pool',
            liquidityToken: 'PltDaiLp',
          },
          WRAP: {
            contract: 'KT1PnFKMA2v1ngvYUh5UKtaDuM5WxskrunFm',
            property: 'token2_pool',
            liquidityToken: 'WrapDaiLp',
          },
        },
      },
      WrapLP: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1LHy8buFGtsZJjMc4C89Wk8VTowqaUwPbY',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      PltDaiLp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1CAmNNoetuxeYZWJLSmbh9N66SDYT8tkBJ',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      WrapDaiLp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1LcFzpTtkDtZwHty12PSuEsscxMPtdA2ZR',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
    },
    mainnet: {
      PLENTY: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          wUSDC: {
            contract: 'KT1PuPNtDFLR6U7e7vDuxunDoKasVT6kMSkz',
            property: 'token2_pool',
            liquidityToken: 'PlentywUSDClp',
          },
          wBUSD: {
            contract: 'KT1XXAavg3tTj12W1ADvd3EEnm1pu6XTmiEF',
            property: 'token2_pool',
            liquidityToken: 'PlentywBUSDlp',
          },
          wWBTC: {
            contract: 'KT19Dskaofi6ZTkrw3Tq4pK7fUqHqCz4pTZ3',
            property: 'token2_pool',
            liquidityToken: 'PlentywWBTClp',
          },
          wMATIC: {
            contract: 'KT1VeNQa4mucRj36qAJ9rTzm4DTJKfemVaZT',
            property: 'token2_pool',
            liquidityToken: 'PlentywMaticlp',
          },
          wLINK: {
            contract: 'KT1XVrXmWY9AdVri6KpxKo4CWxizKajmgzMt',
            property: 'token2_pool',
            liquidityToken: 'Plentywlinklp',
          },
          USDtz: {
            contract: 'KT1D36ZG99YuhoCRZXLL86tQYAbv36bCq9XM',
            property: 'token2_pool',
            liquidityToken: 'Plentyusdtzlp',
          },
          hDAO: {
            contract: 'KT1XutoFJ9dXvWxT7ttG86N2tSTUEpatFVTm',
            property: 'token2_pool',
            liquidityToken: 'Plenty-hDAO-LP',
          },
          ETHtz: {
            contract: 'KT1AbuUaPQmYLsB8n8FdSzBrxvrsm8ctwW1V',
            property: 'token2_pool',
            liquidityToken: 'Plenty-ETHtz-LP',
          },
          wWETH: {
            contract: 'KT1HUnqM6xFJa51PM2xHfLs7s6ARvXungtyq',
            property: 'token2_pool',
            liquidityToken: 'Plenty-wWETH-LP',
          },
          kUSD: {
            contract: 'KT1UNBvCJXiwJY6tmHM7CJUVwNPew53XkSfh',
            property: 'token2_pool',
            liquidityToken: 'PLENTY-kUSD-LP',
          },
          QUIPU: {
            contract: 'KT1NtsnKQ1c3rYB12ZToP77XaJs8WDBvF221',
            property: 'token2_pool',
            liquidityToken: 'Plenty-QUIPU-LP',
          },
          WRAP: {
            contract: 'KT1C2SXoGcje3VVMJHKRVhYXuWuNmv5ztJcw',
            property: 'token2_pool',
            liquidityToken: 'Plentywraplp',
          },
          UNO: {
            contract: 'KT1Wu8fGYxX5DfxYP8P97ZUNzrXFKjsWm7dD',
            property: 'token2_pool',
            liquidityToken: 'Plenty-UNO-LP',
          },
          KALAM: {
            contract: 'KT1HZkD2T4uczgYkZ6fb9gm1fymeJoRuezLz',
            property: 'token2_pool',
            liquidityToken: 'Plentykalamlp',
          },
          SMAK: {
            contract: 'KT1BEC9uHmADgVLXCm3wxN52qJJ85ohrWEaU',
            property: 'token2_pool',
            liquidityToken: 'Plenty-SMAK-LP',
          },
          tzBTC: {
            contract: 'KT1HaDP8fRW7kavK2beST7o4RvzuvZbn5VwV',
            property: 'token2_pool',
            liquidityToken: 'PlentytzBTClp',
          },
          uUSD: {
            contract: 'KT1Cba383ZJpEearqnUyUKUMsgu5Z3TXBgeH',
            property: 'token2_pool',
            liquidityToken: 'Plenty-uUSD-LP',
          },
          GIF: {
            contract: 'KT1Cge8y4JG6Bi8dGKf1bUfpCAv2AiW6MjtW',
            property: 'token2_pool',
            liquidityToken: 'PlentyGiflp',
          },
          YOU: {
            contract: 'KT1EM6NjJdJXmz3Pj13pfu3MWVDwXEQnoH3N',
            property: 'token2_pool',
            liquidityToken: 'Plenty-YOU-LP',
          },
          wUSDT: {
            contract: 'KT1Bi4yoALg6tuP4PKaFNDtSsDdpGLrDiGAS',
            property: 'token2_pool',
            liquidityToken: 'PlentywUSDTlp',
          },
          wDAI: {
            contract: 'KT1KDmpYSDogNtEtEnEaHQLaySuqLr8aEfJW',
            property: 'token2_pool',
            liquidityToken: 'PlentywDAIlp',
          },
          ctez: {
            contract: 'KT1C9gJRfkpPbNdBn3XyYbrUHT6XgfPzZqXP',
            property: 'token2_pool',
            liquidityToken: 'PlentyCtezlp',
          },
        },
      },
      wUSDC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 17,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1PuPNtDFLR6U7e7vDuxunDoKasVT6kMSkz',
            property: 'token1_pool',
            liquidityToken: 'PlentywUSDClp',
          },
          USDtz: {
            contract: 'KT1EJMMismkf6TQdMzgx8hb1HpiiRVV8ZSm4',
            property: 'token2_pool',
            liquidityToken: 'wUSDC-USDtz-LP',
          },
          uUSD: {
            contract: 'KT1JbUFVGrMCpW39UtH97dMkG2nMMrefQLLa',
            property: 'token1_pool',
            liquidityToken: 'uusd-wusdc-LP',
          },
        },
      },
      wBUSD: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1XXAavg3tTj12W1ADvd3EEnm1pu6XTmiEF',
            property: 'token1_pool',
            liquidityToken: 'PlentywBUSDlp',
          },
        },
      },
      wWBTC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 19,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT19Dskaofi6ZTkrw3Tq4pK7fUqHqCz4pTZ3',
            property: 'token1_pool',
            liquidityToken: 'PlentywWBTClp',
          },
        },
      },
      wUSDT: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 18,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1Bi4yoALg6tuP4PKaFNDtSsDdpGLrDiGAS',
            property: 'token1_pool',
            liquidityToken: 'PlentywUSDTlp',
          },
        },
      },
      wDAI: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 5,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1KDmpYSDogNtEtEnEaHQLaySuqLr8aEfJW',
            property: 'token1_pool',
            liquidityToken: 'PlentywDAIlp',
          },
        },
      },
      wMATIC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 11,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1VeNQa4mucRj36qAJ9rTzm4DTJKfemVaZT',
            property: 'token1_pool',
            liquidityToken: 'PlentywMaticlp',
          },
        },
      },
      wLINK: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 10,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1XVrXmWY9AdVri6KpxKo4CWxizKajmgzMt',
            property: 'token1_pool',
            liquidityToken: 'Plentywlinklp',
          },
        },
      },
      wWETH: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 20,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1HUnqM6xFJa51PM2xHfLs7s6ARvXungtyq',
            property: 'token1_pool',
            liquidityToken: 'Plenty-wWETH-LP',
          },
          ETHtz: {
            contract: 'KT196bPdVJqY1DF3y7aQ9Cn5tcGQeMrrjWTp',
            property: 'token2_pool',
            liquidityToken: 'wWETH-ETHtz-LP',
          },
        },
      },
      UNO: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1ErKVqEhG9jxXgUG2KGLW3bNM7zXHX8SDF',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1Wu8fGYxX5DfxYP8P97ZUNzrXFKjsWm7dD',
            property: 'token1_pool',
            liquidityToken: 'Plenty-UNO-LP',
          },
        },
      },
      uUSD: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XRPEPXbZK25r3Htzp2o1x7xdMMmfocKNW',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1Cba383ZJpEearqnUyUKUMsgu5Z3TXBgeH',
            property: 'token1_pool',
            liquidityToken: 'Plenty-uUSD-LP',
          },
          wUSDC: {
            contract: 'KT1JbUFVGrMCpW39UtH97dMkG2nMMrefQLLa',
            property: 'token2_pool',
            liquidityToken: 'uusd-wusdc-LP',
          },
          YOU: {
            contract: 'KT1TnrLFrdemNZ1AnnWNfi21rXg7eknS484C',
            property: 'token2_pool',
            liquidityToken: 'uusd-you-LP',
          },
          uDEFI: {
            contract: 'KT1EAw8hL5zseB3SLpJhBqPQfP9aWrWh8iMW',
            property: 'token2_pool',
            liquidityToken: 'uusd-udefi-LP',
          },
        },
      },
      uDEFI: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XRPEPXbZK25r3Htzp2o1x7xdMMmfocKNW',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 1,
        TOKEN_DECIMAL: 12,
        DEX_PAIRS: {
          uUSD: {
            contract: 'KT1EAw8hL5zseB3SLpJhBqPQfP9aWrWh8iMW',
            property: 'token1_pool',
            liquidityToken: 'uusd-udefi-LP',
          },
        },
      },
      YOU: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Xobej4mc6XgEjDoJoHtTKgbD1ELMvcQuL',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1EM6NjJdJXmz3Pj13pfu3MWVDwXEQnoH3N',
            property: 'token1_pool',
            liquidityToken: 'Plenty-YOU-LP',
          },
          uUSD: {
            contract: 'KT1TnrLFrdemNZ1AnnWNfi21rXg7eknS484C',
            property: 'token1_pool',
            liquidityToken: 'uusd-you-LP',
          },
        },
      },
      USDtz: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1D36ZG99YuhoCRZXLL86tQYAbv36bCq9XM',
            property: 'token1_pool',
            liquidityToken: 'Plentyusdtzlp',
          },
          kUSD: {
            contract: 'KT1TnsQ6JqzyTz5PHMsGj28WwJyBtgc146aJ',
            property: 'token1_pool',
            liquidityToken: 'kUSD-USDtz-LP',
          },
          wUSDC: {
            contract: 'KT1EJMMismkf6TQdMzgx8hb1HpiiRVV8ZSm4',
            property: 'token1_pool',
            liquidityToken: 'wUSDC-USDtz-LP',
          },
        },
      },
      WRAP: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1LRboPna9yQY9BrjtQYDS1DVxhKESK4VVd',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1C2SXoGcje3VVMJHKRVhYXuWuNmv5ztJcw',
            property: 'token1_pool',
            liquidityToken: 'Plentywraplp',
          },
        },
      },
      GIF: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XTxpQvo7oRCqp85LikEZgAZ22uDxhbWJv',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1Cge8y4JG6Bi8dGKf1bUfpCAv2AiW6MjtW',
            property: 'token1_pool',
            liquidityToken: 'PlentyGiflp',
          },
        },
      },
      tzBTC: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1PWx2mnDueood7fEmfbBDKx1D9BAnnXitn',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 8,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1HaDP8fRW7kavK2beST7o4RvzuvZbn5VwV',
            property: 'token1_pool',
            liquidityToken: 'PlentytzBTClp',
          },
        },
      },
      ctez: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1SjXiUX63QvdNMcM2m492f7kuf8JxXRLp4',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1C9gJRfkpPbNdBn3XyYbrUHT6XgfPzZqXP',
            property: 'token1_pool',
            liquidityToken: 'PlentyCtezlp',
          },
        },
      },
      KALAM: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1A5P4ejnLix13jtadsfV9GCnXLMNnab8UT',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 10,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1HZkD2T4uczgYkZ6fb9gm1fymeJoRuezLz',
            property: 'token1_pool',
            liquidityToken: 'Plentykalamlp',
          },
        },
      },
      kUSD: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1K9gCRgaLRFKTErYt1wVxA3Frb9FjasjTV',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          USDtz: {
            contract: 'KT1TnsQ6JqzyTz5PHMsGj28WwJyBtgc146aJ',
            property: 'token2_pool',
            liquidityToken: 'kUSD-USDtz-LP',
          },
          PLENTY: {
            contract: 'KT1UNBvCJXiwJY6tmHM7CJUVwNPew53XkSfh',
            property: 'token1_pool',
            liquidityToken: 'PLENTY-kUSD-LP',
          },
        },
      },
      hDAO: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1AFA2mwNUMNd4SsujE1YYp29vd8BZejyKW',
        READ_TYPE: 'FA2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1XutoFJ9dXvWxT7ttG86N2tSTUEpatFVTm',
            property: 'token1_pool',
            liquidityToken: 'Plenty-hDAO-LP',
          },
        },
      },
      ETHtz: {
        ICON: '',
        TOKEN_CONTRACT: 'KT19at7rQUvyjxnZ2fBv7D9zc8rkyG7gAoU8',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1AbuUaPQmYLsB8n8FdSzBrxvrsm8ctwW1V',
            property: 'token1_pool',
            liquidityToken: 'Plenty-ETHtz-LP',
          },
          wWETH: {
            contract: 'KT196bPdVJqY1DF3y7aQ9Cn5tcGQeMrrjWTp',
            property: 'token1_pool',
            liquidityToken: 'wWETH-ETHtz-LP',
          },
        },
      },
      SMAK: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1TwzD6zV3WeJ39ukuqxcfK2fJCnhvrdN1X',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 3,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1BEC9uHmADgVLXCm3wxN52qJJ85ohrWEaU',
            property: 'token1_pool',
            liquidityToken: 'Plenty-SMAK-LP',
          },
        },
      },
      QUIPU: {
        ICON: '',
        TOKEN_CONTRACT: 'KT193D4vozYnhGJQVtw7CoxxqphqUEEwK6Vb',
        READ_TYPE: 'FA1.2',
        CALL_TYPE: 'FA2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        DEX_PAIRS: {
          PLENTY: {
            contract: 'KT1NtsnKQ1c3rYB12ZToP77XaJs8WDBvF221',
            property: 'token1_pool',
            liquidityToken: 'Plenty-QUIPU-LP',
          },
        },
      },
      PlentywUSDClp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Gz1mx1jm7JHqU7GuMVWF6soB9RjsfLN3o',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      PlentywBUSDlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UC3vcVZ4K9b39uQxaMNA2N1RuJXKLCnoA',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      PlentywWBTClp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1La1qZiJtDRcd9ek8w5KYD47i9MQqAQHmP',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      PlentywMaticlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1WCGPFvy97wwGxewKfvTr1QYPvpEgUKToS',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      Plentywlinklp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Brqb3JvXNKzwjW82F8pUAxQ7ipCfApWki',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      Plentyusdtzlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT18qSo4Ch2Mfq4jP3eME7SWHB8B8EDTtVBu',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'kUSD-USDtz-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1HEdGi7rq1zgZ68dhAtKMZYKeD3EM5vYdf',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'wUSDC-USDtz-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1FaoPYSyT4itmgTncvdshV7SeqqXk9RJXd',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 6,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-hDAO-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1B2SzTBtb7PgTePbDLV5BmUZQ2PC1sdSHZ',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-ETHtz-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VvcbAjMWHVUbhLENpiVBejbzXYDt3PusE',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-wWETH-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1SULRhejhoBWUmMnU53YHJrQZ3rxqsamdm',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      'PLENTY-kUSD-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1XTpd8JPexGxBL2dAmU9h2o9fcd9LEUG2t',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-QUIPU-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1VDSyTqS95wkB4tRGy6saMa4izRKwrEg26',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'wWETH-ETHtz-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1GubVxQVJucUJfJvysvAJKWpLwj3LKuWHn',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      Plentywraplp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1AHndbp9xVpaJrfTHfYzSXKHDVHMdKxcW2',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-UNO-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1B6ALzWXQp5wUavuo2VTMNjRdTGm8wx37w',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      Plentykalamlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1G3QTnrpWNhZr9x3Prprw3GH6gAMqV113D',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 14,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-SMAK-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1NLZah1MKeWuveQvdsCqAUCjksKw8J296z',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 10,
        CALL_TYPE: 'FA1.2',
      },
      PlentytzBTClp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1SqQimKz3RbQbckpSHhn4nanUmDuRqkFH3',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-uUSD-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1E8CrG6uznYAG9vZVGtApMJwwTScxPEUKq',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 14,
        CALL_TYPE: 'FA1.2',
      },
      PlentyGiflp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1MVLRQ7K7275oHDvbtKcUzNJsPBstZGKUp',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 13,
        CALL_TYPE: 'FA1.2',
      },
      'Plenty-YOU-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1UaU5fbSYqYeFmhmsjLkqQXZ1ZG54Qs2vh',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 15,
        CALL_TYPE: 'FA1.2',
      },
      PlentywUSDTlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1PcM1LUqgVdkXqKvZ4CeC9aiwLgYYCEHMH',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      PlentywDAIlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT19vdNapeT6MALXvkvW745KiVKGXmD4AZq5',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 18,
        CALL_TYPE: 'FA1.2',
      },
      PlentyCtezlp: {
        ICON: '',
        TOKEN_CONTRACT: 'KT1LdX5pUkZZGwYPePcAFSpETbqfbGCwJpfw',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'uusd-wusdc-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1A2W6mBjn49yqzjJbt9T7xNVD9gDeL45qD',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 9,
        CALL_TYPE: 'FA1.2',
      },
      'uusd-you-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1Tmncfgpp4ZSp6aEogL7uhBqHTiKsSPegK',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
      'uusd-udefi-LP': {
        ICON: '',
        TOKEN_CONTRACT: 'KT1RQvdYD9yc763j8FiVLyXbKPVVbZqGRx5m',
        READ_TYPE: 'FA1.2',
        TOKEN_ID: 0,
        TOKEN_DECIMAL: 12,
        CALL_TYPE: 'FA1.2',
      },
    },
  },
  POOLS: {
    mainnet: {
      PLENTY: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CONTRACT: 'KT1QqjR4Fj9YegB37PQEqXUPHmFbhz6VJtwE',
            DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 0,
            DECIMAL: 18,
            CARD_TYPE: 'PLENTY',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended October 1',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CONTRACT: 'KT1UDe1YP963CQSb5xN7cQ1X8NJ2pUyjGw5T',
            DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 0,
            DECIMAL: 18,
            CARD_TYPE: 'PLENTY',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
        ],
      },
      wMATIC: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
            CONTRACT: 'KT1TNzH1KiVsWh9kpFrWACrDNnfK4ihvGAZs',
            DEX: 'KT1RsfuBee5o7GtYrdB7bzQ1M6oVgyBnxY4S',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 11,
            DECIMAL: 18,

            CARD_TYPE: 'wMATIC',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
            CONTRACT: 'KT1XherecVvrE6X4PV5RTwdEKNzA294ZE9T9',
            DEX: 'KT1RsfuBee5o7GtYrdB7bzQ1M6oVgyBnxY4S',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 11,
            DECIMAL: 18,

            CARD_TYPE: 'wMATIC',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended August 26',
            bannerType: 'alert',
          },
        ],
      },
      wLINK: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
            CONTRACT: 'KT1JCkdS3x5hTWdrTQdzK6vEkeAdQzsm2wzf',
            DEX: 'KT1Lpysr4nzcFegC9ci9kjoqVidwoanEmJWt',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 10,
            DECIMAL: 18,

            CARD_TYPE: 'wLINK',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
            CONTRACT: 'KT1KyxPitU1xNbTriondmAFtPEcFhjSLV1hz',
            DEX: 'KT1Lpysr4nzcFegC9ci9kjoqVidwoanEmJWt',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 10,
            DECIMAL: 18,

            CARD_TYPE: 'wLINK',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended August 26',
            bannerType: 'alert',
          },
        ],
      },
      ETHtz: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT19at7rQUvyjxnZ2fBv7D9zc8rkyG7gAoU8',
            CONTRACT: 'KT1J7v85udA8GnaBupacgY9mMvrb8zQdYb3E',
            DEX: 'KT1Evsp2yA19Whm24khvFPcwimK6UaAJu8Zo',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'ETHtz',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT19at7rQUvyjxnZ2fBv7D9zc8rkyG7gAoU8',
            CONTRACT: 'KT19asUVzBNidHgTHp8MP31YSphooMb3piWR',
            DEX: 'KT1Evsp2yA19Whm24khvFPcwimK6UaAJu8Zo',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 18,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'ETHtz',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended September 2',
            bannerType: 'alert',
          },
        ],
      },
      hDAO: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT1AFA2mwNUMNd4SsujE1YYp29vd8BZejyKW',
            CONTRACT: 'KT1Vs8gqh7YskPnUQMfmjogZh3A5ZLpqQGcg',
            DEX: 'KT1QxLqukyfohPV5kPkw97Rs6cw1DDDvYgbB',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 6,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'hDAO',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT1AFA2mwNUMNd4SsujE1YYp29vd8BZejyKW',
            CONTRACT: 'KT1Ga15wxGR5oWK1vBG2GXbjYM6WqPgpfRSP',
            DEX: 'KT1QxLqukyfohPV5kPkw97Rs6cw1DDDvYgbB',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 6,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'hDAO',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended September 2',
            bannerType: 'alert',
          },
        ],
      },
      USDtz: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9',
            CONTRACT: 'KT1K5cgrw1A8WTiizZ5b6TxNdFnBq9AtyQ7X',
            DEX: 'KT1WxgZ1ZSfMgmsSDDcUn8Xn577HwnQ7e1Lb',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 6,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'USDtz',
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
          },
          {
            TOKEN: 'KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9',
            CONTRACT: 'KT1MBqc3GHpApBXaBZyvY63LF6eoFyTWtySn',
            DEX: 'KT1WxgZ1ZSfMgmsSDDcUn8Xn577HwnQ7e1Lb',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 6,
            TOKEN_ID: 0,
            DECIMAL: 18,

            CARD_TYPE: 'USDtz',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended August 26',
            bannerType: 'alert',
          },
        ],
      },
      WRAP: {
        active: [],
        inactive: [
          {
            TOKEN: 'KT1LRboPna9yQY9BrjtQYDS1DVxhKESK4VVd',
            CONTRACT: 'KT18oB3x8SLxMJq2o9hKNupbZZ5ZMsgr2aho',
            DEX: 'KT1FG63hhFtMEEEtmBSX2vuFmP87t9E7Ab4t',
            TYPE: 'FA2',
            TOKEN_DECIMAL: 8,
            TOKEN_ID: 0,
            DECIMAL: 18,
            CARD_TYPE: 'WRAP',
            withdrawalFeeType: 'type1',
            message: 'Rewards ended August 11',
            bannerType: 'alert',
          },
        ],
      },
    },
    testnet: {},
  },
  FARMS: {
    mainnet: {
      'uUSD - YOU': {
        active: [
          {
            LP_TOKEN: 'KT1Tmncfgpp4ZSp6aEogL7uhBqHTiKsSPegK',
            CONTRACT: 'KT1KGKzNGX1NDP3hGcipzyqVMCkwWbH76NJU',
            DEX: 'KT1TnrLFrdemNZ1AnnWNfi21rXg7eknS484C',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'uUSD / YOU LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=uUSD&tokenB=YOU',
            isDualFarm: false,
            message: 'YOU rewards 😍',
            bannerType: 'info',
          },
        ],
        inactive: [],
      },
      'uUSD - wUSDC': {
        active: [
          {
            LP_TOKEN: 'KT1A2W6mBjn49yqzjJbt9T7xNVD9gDeL45qD',
            CONTRACT: 'KT1DfYVe4CaE9S6Sm3SEfhFYVZ9XzJbkQDqs',
            DEX: 'KT1JbUFVGrMCpW39UtH97dMkG2nMMrefQLLa',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'uUSD / wUSDC LP',
            TOKEN_DECIMAL: 9,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=uUSD&tokenB=wUSDC',
            isDualFarm: false,
            message: 'YOU rewards 😍',
            bannerType: 'info',
          },
        ],
        inactive: [],
      },
      'uUSD - uDEFI': {
        active: [
          {
            LP_TOKEN: 'KT1RQvdYD9yc763j8FiVLyXbKPVVbZqGRx5m',
            CONTRACT: 'KT1RENb4rWNFPP5QJSYT4rRGGsk1tPgLLwu2',
            DEX: 'KT1EAw8hL5zseB3SLpJhBqPQfP9aWrWh8iMW',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'uUSD / uDEFI LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=uUSD&tokenB=uDEFI',
            isDualFarm: false,
            message: 'YOU rewards 😍',
            bannerType: 'info',
          },
        ],
        inactive: [],
      },
      'PLENTY - cTez': {
        active: [
          {
            LP_TOKEN: 'KT1LdX5pUkZZGwYPePcAFSpETbqfbGCwJpfw',
            CONTRACT: 'KT1MfMMsYX34Q9cEaPtk4qkQ6pojA7D2nsgr',
            DEX: 'KT1C9gJRfkpPbNdBn3XyYbrUHT6XgfPzZqXP',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / cTez LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=ctez',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - XTZ': {
        active: [
          {
            LP_TOKEN: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            CONTRACT: 'KT1JQAZqShNMakSNXc2cgTzdAWZFemGcU6n1',
            DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / XTZ LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink:
              'https://quipuswap.com/invest/add-liquidity/KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            isDualFarm: false,
          },
        ],
        inactive: [
          {
            LP_TOKEN: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            CONTRACT: 'KT1BfQLAsQNX8BjSBzgjTLx3GTd3qhwLoWNz',
            DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / XTZ LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 9',
            bannerType: 'alert',
            isDualFarm: false,
          },
        ],
      },
      'KALAM - XTZ': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1J3wTYb4xk5BsSBkg6ML55bX1xq7desS34',
            CONTRACT: 'KT1DjDZio7k2GJwCJCXwK82ing3n51AE55DW',
            DEX: 'KT1J3wTYb4xk5BsSBkg6ML55bX1xq7desS34',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'KALAM / XTZ LP',
            TOKEN_DECIMAL: 6,
            TYPE: 'FA2',
            TOKEN_ID: 0,
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1A5P4ejnLix13jtadsfV9GCnXLMNnab8UT',
            DECIMAL: 18,
            withdrawalFeeType: 'type3',
            message: 'Rewards ended June 22',
            bannerType: 'alert',
            isDualFarm: false,
          },
        ],
      },
      'PLENTY - wBUSD': {
        active: [
          {
            LP_TOKEN: 'KT1UC3vcVZ4K9b39uQxaMNA2N1RuJXKLCnoA',
            CONTRACT: 'KT1KJhxkCpZNwAFQURDoJ79hGqQgSC9UaWpG',
            DEX: 'KT1XXAavg3tTj12W1ADvd3EEnm1pu6XTmiEF',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wBUSD LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 18,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wBUSD',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - wUSDC': {
        active: [
          {
            LP_TOKEN: 'KT1Gz1mx1jm7JHqU7GuMVWF6soB9RjsfLN3o',
            CONTRACT: 'KT1Kp3KVT4nHFmSuL8bvETkgQzseUYP3LDBy',
            DEX: 'KT1PuPNtDFLR6U7e7vDuxunDoKasVT6kMSkz',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wUSDC LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDC',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - wWBTC': {
        active: [
          {
            LP_TOKEN: 'KT1La1qZiJtDRcd9ek8w5KYD47i9MQqAQHmP',
            CONTRACT: 'KT1M82a7arHVwcwaswnNUUuCnQ45xjjGKNd1',
            DEX: 'KT19Dskaofi6ZTkrw3Tq4pK7fUqHqCz4pTZ3',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wWBTC LP',
            TOKEN_DECIMAL: 13,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wWBTC',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - wMATIC': {
        active: [
          {
            LP_TOKEN: 'KT1WCGPFvy97wwGxewKfvTr1QYPvpEgUKToS',
            CONTRACT: 'KT1UP9XHQigWMqNXYp9YXaCS1hV9jJkCF4h4',
            DEX: 'KT1VeNQa4mucRj36qAJ9rTzm4DTJKfemVaZT',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wMATIC LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wMATIC',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - wLINK': {
        active: [
          {
            LP_TOKEN: 'KT1Brqb3JvXNKzwjW82F8pUAxQ7ipCfApWki',
            CONTRACT: 'KT1UqnQ6b1EwQgYiKss4mDL7aktAHnkdctTQ',
            DEX: 'KT1XVrXmWY9AdVri6KpxKo4CWxizKajmgzMt',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wLINK LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wLINK',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - USDtz': {
        active: [
          {
            LP_TOKEN: 'KT18qSo4Ch2Mfq4jP3eME7SWHB8B8EDTtVBu',
            CONTRACT: 'KT1VCrmywPNf8ZHH95HKHvYA4bBQJPa8g2sr',
            DEX: 'KT1D36ZG99YuhoCRZXLL86tQYAbv36bCq9XM',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / USDtz LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=USDtz',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - hDAO': {
        active: [
          {
            LP_TOKEN: 'KT1B2SzTBtb7PgTePbDLV5BmUZQ2PC1sdSHZ',
            CONTRACT: 'KT1W3DtcPXbD7MMmtUdk3F352G6CYFSpwUUS',
            DEX: 'KT1XutoFJ9dXvWxT7ttG86N2tSTUEpatFVTm',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / hDAO LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=hDAO',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - ETHtz': {
        active: [
          {
            LP_TOKEN: 'KT1VvcbAjMWHVUbhLENpiVBejbzXYDt3PusE',
            CONTRACT: 'KT1EVfYFoSpte3PnE4tPoWuj1DhNPVQwrW5Y',
            DEX: 'KT1AbuUaPQmYLsB8n8FdSzBrxvrsm8ctwW1V',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / ETHtz LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=ETHtz',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - wWETH': {
        active: [
          {
            LP_TOKEN: 'KT1SULRhejhoBWUmMnU53YHJrQZ3rxqsamdm',
            CONTRACT: 'KT1CBh8BKFV6xAH42hEdyhkijbwzYSKW2ZZC',
            DEX: 'KT1HUnqM6xFJa51PM2xHfLs7s6ARvXungtyq',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wWETH LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wWETH',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - kUSD': {
        active: [
          {
            LP_TOKEN: 'KT1XTpd8JPexGxBL2dAmU9h2o9fcd9LEUG2t',
            CONTRACT: 'KT1MmAy4mSbZZVzPoYbK3M4z3GWUo54UTiQR',
            DEX: 'KT1UNBvCJXiwJY6tmHM7CJUVwNPew53XkSfh',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / kUSD LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=kUSD',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - QUIPU': {
        active: [
          {
            LP_TOKEN: 'KT1VDSyTqS95wkB4tRGy6saMa4izRKwrEg26',
            CONTRACT: 'KT1FsMiweyRTog9GGNC22hiMTFVRPrGs3eto',
            DEX: 'KT1NtsnKQ1c3rYB12ZToP77XaJs8WDBvF221',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / QUIPU LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=QUIPU',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - WRAP': {
        active: [
          {
            LP_TOKEN: 'KT1AHndbp9xVpaJrfTHfYzSXKHDVHMdKxcW2',
            CONTRACT: 'KT1K9kLuhq9AJjDAgbJdKGBiP9927WsRnjP6',
            DEX: 'KT1C2SXoGcje3VVMJHKRVhYXuWuNmv5ztJcw',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / WRAP LP',
            TOKEN_DECIMAL: 13,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=WRAP',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - UNO': {
        active: [
          {
            LP_TOKEN: 'KT1B6ALzWXQp5wUavuo2VTMNjRdTGm8wx37w',
            CONTRACT: 'KT1CWNVmHs6RRbLzwA3P19h7Wa9smnDrAgpS',
            DEX: 'KT1Wu8fGYxX5DfxYP8P97ZUNzrXFKjsWm7dD',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / UNO LP',
            TOKEN_DECIMAL: 13,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=UNO',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - SMAK': {
        active: [
          {
            LP_TOKEN: 'KT1NLZah1MKeWuveQvdsCqAUCjksKw8J296z',
            CONTRACT: 'KT1VwZPZ4bcPQYS1C4yRvmU4giQDXhEV81WD',
            DEX: 'KT1BEC9uHmADgVLXCm3wxN52qJJ85ohrWEaU',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / SMAK LP',
            TOKEN_DECIMAL: 10,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=SMAK',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - KALAM': {
        active: [
          {
            LP_TOKEN: 'KT1G3QTnrpWNhZr9x3Prprw3GH6gAMqV113D',
            CONTRACT: 'KT1UTvMuyRggQe9q1hrh7YLh7vxffX2egtS6',
            DEX: 'KT1HZkD2T4uczgYkZ6fb9gm1fymeJoRuezLz',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / KALAM LP',
            TOKEN_DECIMAL: 14,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=KALAM',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - tzBTC': {
        active: [
          {
            LP_TOKEN: 'KT1SqQimKz3RbQbckpSHhn4nanUmDuRqkFH3',
            CONTRACT: 'KT1RwFV1xQU2E9TsXe1qzkdwAgFWaKk8bfAa',
            DEX: 'KT1HaDP8fRW7kavK2beST7o4RvzuvZbn5VwV',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / tzBTC LP',
            TOKEN_DECIMAL: 13,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=tzBTC',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - uUSD': {
        active: [
          {
            LP_TOKEN: 'KT1E8CrG6uznYAG9vZVGtApMJwwTScxPEUKq',
            CONTRACT: 'KT1HSYQ9NLTQufuvNUwMhLY7B9TX8LDUfgsr',
            DEX: 'KT1Cba383ZJpEearqnUyUKUMsgu5Z3TXBgeH',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / uUSD LP',
            TOKEN_DECIMAL: 14,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=uUSD',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - GIF': {
        active: [],
        inactive: [
          {
            LP_TOKEN: 'KT1MVLRQ7K7275oHDvbtKcUzNJsPBstZGKUp',
            CONTRACT: 'KT1UH21n4iwXu7gGrh34RKZfRewpcgQLdbXq',
            DEX: 'KT1Cge8y4JG6Bi8dGKf1bUfpCAv2AiW6MjtW',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            dualInfo: {
              tokenFirst: {
                symbol: 'PLENTY',
                tokenContract: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
                tokenDecimal: 18,
                tokenType: 'FA1.2',
                tokenId: 0,
                rewardContract: 'KT1KPSKAsmM9k28Lsbk1mmrujHpCnQHxKhKS',
              },
              tokenSecond: {
                symbol: 'GIF',
                tokenContract: 'KT1XTxpQvo7oRCqp85LikEZgAZ22uDxhbWJv',
                tokenDecimal: 9,
                tokenType: 'FA2',
                tokenId: 0,
                rewardContract: 'KT1LQS3kyhaaW6thmW96anY71gZ92ECGU7Ja',
              },
            },
            CARD_TYPE: 'PLENTY / GIF Dual LP',
            TOKEN_DECIMAL: 13,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            message: 'Rewards ended October 19',
            bannerType: 'alert',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=GIF',
            isDualFarm: true,
          },
        ],
      },
      'PLENTY - YOU': {
        active: [
          {
            LP_TOKEN: 'KT1UaU5fbSYqYeFmhmsjLkqQXZ1ZG54Qs2vh',
            CONTRACT: 'KT1MkXtVBuCKtxqSh7APrg2d7ThGBmEf4hnw',
            DEX: 'KT1EM6NjJdJXmz3Pj13pfu3MWVDwXEQnoH3N',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / YOU LP',
            TOKEN_DECIMAL: 15,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=YOU',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - wDAI': {
        active: [
          {
            LP_TOKEN: 'KT19vdNapeT6MALXvkvW745KiVKGXmD4AZq5',
            CONTRACT: 'KT1FJzDx9AwbuNHjhzQuUxxKUMA9BQ7DVfGn',
            DEX: 'KT1KDmpYSDogNtEtEnEaHQLaySuqLr8aEfJW',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wDAI LP',
            TOKEN_DECIMAL: 18,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wDAI',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
      'PLENTY - wUSDT': {
        active: [
          {
            LP_TOKEN: 'KT1PcM1LUqgVdkXqKvZ4CeC9aiwLgYYCEHMH',
            CONTRACT: 'KT1S4XjwGtk55TmsMqSdazEMrH4pGA3NMXhz',
            DEX: 'KT1Bi4yoALg6tuP4PKaFNDtSsDdpGLrDiGAS',
            TOKEN_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            CARD_TYPE: 'PLENTY / wUSDT LP',
            TOKEN_DECIMAL: 12,
            TYPE: 'FA1.2',
            LP_DECIMAL: 12,
            TEMP_ADDRESS: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            DECIMAL: 18,
            withdrawalFeeType: 'type2',
            liquidityLink: '/liquidity/add?tokenA=PLENTY&tokenB=wUSDT',
            isDualFarm: false,
          },
        ],
        inactive: [],
      },
    },
    testnet: {},
  },
  PONDS: {
    mainnet: {
      KALAM: {
        active: [],
        inactive: [
          {
            REWARD_TOKEN: 'KT1A5P4ejnLix13jtadsfV9GCnXLMNnab8UT',
            STAKING_TOKEN: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            STAKING_CONTRACT: 'KT1WfLprabHVTnNhWFigmopAduUpxG5HKvNf',
            STAKING_TOKEN_DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            REWARD_TOKEN_DEX: 'KT1J3wTYb4xk5BsSBkg6ML55bX1xq7desS34',
            STAKING_TOKEN_DECIMAL: 18,
            REWARD_TOKEN_DECIMAL: 10,
            TYPE: 'FA1.2',
            CARD_TYPE: 'KALAM',
          },
        ],
      },
      WRAP: {
        active: [],
        inactive: [
          {
            REWARD_TOKEN: 'KT1LRboPna9yQY9BrjtQYDS1DVxhKESK4VVd',
            STAKING_TOKEN: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
            STAKING_CONTRACT: 'KT1GotpjdBaxt2GiMFcQExLEk9GTfYo4UoTa',
            STAKING_TOKEN_DEX: 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z',
            REWARD_TOKEN_DEX: 'KT1FG63hhFtMEEEtmBSX2vuFmP87t9E7Ab4t',
            STAKING_TOKEN_DECIMAL: 18,
            REWARD_TOKEN_DECIMAL: 8,
            TYPE: 'FA1.2',
            CARD_TYPE: 'WRAP',
          },
        ],
      },
    },
    testnet: {},
  },
  withdrawalFeeDistribution: {
    type1: [
      { block: 8192, rate: 25, duration: '< 3 days' },
      { block: 16384, rate: 12.5, duration: '< 6 days' },
      { block: 24576, rate: 10, duration: '< 9 days' },
      { block: 24576, rate: 4, duration: '> 9 days' },
    ],
    type2: [
      { block: 24576, rate: 4, duration: '< 9 days' },
      { block: 24576, rate: 0, duration: '> 9 days' },
    ],
    type3: [{ block: 0, rate: 0, duration: '> 0 days' }],
    type4: [
      { block: 8192, rate: 25, duration: '< 3 days' },
      { block: 16384, rate: 12.5, duration: '< 6 days' },
      { block: 24576, rate: 10, duration: '< 9 days' },
      { block: 24576, rate: 2, duration: '> 9 days' },
    ],
  },
  xPlenty: {
    testnet: {
      plentyTokenContract: {
        address: 'KT1PHbg4Dqmg9zWwuWQjo4dDTsgJ5svdyXdH',
        balancesMapId: 22612,
        decimal: 18,
      },
      xPlentyTokenContract: {
        address: 'KT1PP3YaYF4iNTyryjjKe5WrqnnMbQPLqd4E',
        balancesMapId: 40509,
        decimal: 18,
      },
      rewardManager: {
        address: 'KT1M1uU6br1ijvZVGe6FYwqf3kHK4GmUfDso',
      },
      xPlentyCurve: {
        address: 'KT1Luq2wvCFJ8zDCJmqWsyXLXeffhwcTExJe',
        bigMapExpression: 'exprudLL27ekHKb2ZJCTRomMHjKqPK8CmJjDpk2cY2shwagF3woAnn',
      },
    },
    mainnet: {
      plentyTokenContract: {
        address: 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
        balancesMapId: 3943,
        decimal: 18,
      },
      xPlentyTokenContract: {
        address: 'KT1Rpviewjg82JgjGfAKFneSupjAR1kUhbza',
        balancesMapId: 18153,
        decimal: 18,
      },
      rewardManager: {
        address: 'KT1MCgouivQ2rzam5hA2gqF1eMtY5i6ndJvT',
      },
      xPlentyCurve: {
        address: 'KT1PxkrCckgh5fA5v2cZEE2bX5q2RV1rv8dj',
        bigMapExpression: 'exprudkkDPkJ6KmqHyZ1VT7aZHYji3s7FnkKayYkDBrmUemSbsFwSJ',
      },
    },
  },
  NETWORK: 'mainnet',
  WALLET_NETWORK: 'mainnet',
  ADMIN_ADDRESS: 'KT1GpTEq4p2XZ8w9p5xM7Wayyw5VR7tb3UaW',
  BURNER: 'tz1ZnK6zYJrC9PfKCPryg9tPW6LrERisTGtg',
};
